import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import { Button, Chip } from "@legion-ui/core";
import Lottie from "react-lottie-player";
import { IconArrowRight } from "@tabler/icons-react";
import lottieJson from "./Landingnew2.json";
import lottieJsonTwo from "./lottie_json_two.json";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div id="who-you-are" style={{
      "marginBottom": "48px"
    }}>
  <div style={{
        "marginBottom": "24px"
      }}>
    <h2>Let us know, who you are</h2>
    <p className="text-tertiary-60" style={{
          "fontSize": "1rem"
        }}>
      By clicking one of these cards below, we’ll recognize who you are
    </p>
  </div>
  <div className="grid sm-grid-cols-1 grid-cols-2 sm-gap-y-6 gap-y-6 gap-x-6">
    <div className="bg-tertiary-100 p-8 rounded-2xl border border-tertiary-20">
      <div className="role-card">
        <div className="flex justify-center items-center bg-warning-500 w-14-125 h-14-125 rounded-lg">
          <object type="image/svg+xml" data="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/home/cube.svg" aria-label="cube icon">
            Cube Icon
          </object>
        </div>
        <h2 className="text-xl font-bold text-secondary-70 role-title">Designer</h2>
      </div>
      <div className="role-description">
        <p className="text-tertiary-60" style={{
              "minHeight": "64px"
            }}>
          Let’s find out everything you before start designing with
        </p>
      </div>
      <a className="Anchor" href="/design/getting-started/for-telkom-employee">
        <div style={{
              "display": "flex",
              "alignItems": "center",
              "fontWeight": "700"
            }} className="role-anchor">
          Yes, I am a Designer <IconArrowRight size={20} style={{
                "marginLeft": "10px"
              }} mdxType="IconArrowRight" />
        </div>
      </a>
    </div>
    <div className="bg-tertiary-100 p-8 rounded-2xl border border-tertiary-20">
      <div className="role-card">
        <div className="flex justify-center items-center bg-danger-500 w-14-125 h-14-125 rounded-lg">
          <object type="image/svg+xml" data="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/home/dashboard.svg" aria-label="dashboard icon">
            Dashboard Icon
          </object>
        </div>
        <h2 className="text-xl font-bold text-secondary-70 role-title">Developer</h2>
      </div>
      <div className="role-description">
        <p className="text-tertiary-60" style={{
              "minHeight": "64px"
            }}>
          Find out how to setup, configure, and use our library or code assets
        </p>
      </div>
      <a className="Anchor" href="/development/installation/website">
        <div style={{
              "display": "flex",
              "alignItems": "center",
              "fontWeight": "700"
            }} className="role-anchor">
          Yes, I am a Developer <IconArrowRight size={20} style={{
                "marginLeft": "10px"
              }} mdxType="IconArrowRight" />
        </div>
      </a>
    </div>
  </div>
    </div>
    <div id="before-designing" style={{
      "marginBottom": "48px"
    }}>
  <div style={{
        "marginBottom": "24px"
      }}>
    <h2>Find out your brand &amp; assets</h2>
    <p className="text-tertiary-60" style={{
          "fontSize": "1rem"
        }}>
      Take a look first to components we have and pattern we used
    </p>
  </div>
  <div className="grid sm-grid-cols-1 grid-cols-3 sm-gap-y-6 gap-x-6">
    <div className="bg-tertiary-100 p-6 rounded-2xl border border-tertiary-20">
      <h2 className="text-xl font-bold text-secondary-70 mt-0">Foundations</h2>
      <p className="text-tertiary-60 mt-4 mb-4" style={{
            "minHeight": "96px"
          }}>
        Fundamental brand property used in styling components or crafting screen
      </p>
      <a className="Anchor" href="/foundation/overview">
        <div style={{
              "display": "flex",
              "alignItems": "center"
            }} className="role-anchor">
          See all Foundations <IconArrowRight size={20} style={{
                "marginLeft": "10px"
              }} mdxType="IconArrowRight" />
        </div>
      </a>
    </div>
    <div className="bg-tertiary-100 p-6 rounded-2xl border border-tertiary-20">
      <h2 className="text-xl font-bold text-secondary-70 mt-0">Components</h2>
      <p className="text-tertiary-60 mt-4 mb-4" style={{
            "minHeight": "96px"
          }}>
        Our core set of components are accessible and themeable accross products
      </p>
      <a className="Anchor" href="/components/overview">
        <div style={{
              "display": "flex",
              "alignItems": "center"
            }} className="role-anchor">
          See all components <IconArrowRight size={20} style={{
                "marginLeft": "10px"
              }} mdxType="IconArrowRight" />
        </div>
      </a>
    </div>
    <div className="bg-tertiary-100 p-6 rounded-2xl border border-tertiary-20">
      <h2 className="text-xl font-bold text-secondary-70 mt-0">Patterns</h2>
      <p className="text-tertiary-60 mt-4 mb-4" style={{
            "minHeight": "96px"
          }}>
        Pattterns Library is collections of typical design problems, recommendation and solution
      </p>
      <a className="Anchor" href="/pattern/overview">
        <div style={{
              "display": "flex",
              "alignItems": "center"
            }} className="role-anchor">
          See all patterns <IconArrowRight size={20} style={{
                "marginLeft": "10px"
              }} mdxType="IconArrowRight" />
        </div>
      </a>
    </div>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      